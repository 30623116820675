import styled from "@emotion/styled";

export const Quote = styled.q`
  font-style: italic;
  quotes: "“" "”" "‘" "’";

  &:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }
`;
