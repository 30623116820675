import React, { useRef, useCallback } from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectProps } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

type MultiValue = string[];

type MultiSelectOnChangeCb = NonNullable<SelectProps["onChange"]>;

interface MultiSelectProps {
  label: string;
  value: MultiValue;
  onChange: (value: MultiValue) => void;
  options: Array<{ value: string; label: string }>;
  className?: string;
}

export const MultiSelect: React.FunctionComponent<MultiSelectProps> = ({
  label,
  value,
  onChange,
  options,
  className,
}) => {
  const labelRef = useRef<HTMLLabelElement>(null);
  const id = `multi-select-${label.replace(/\W/g, "").toLowerCase()}`;

  const handleChange = useCallback<MultiSelectOnChangeCb>(
    (event) => {
      onChange(event.target.value as MultiValue);
    },
    [onChange]
  );

  return (
    <FormControl variant="outlined" className={className}>
      <InputLabel ref={labelRef} htmlFor={id}>
        {label}
      </InputLabel>
      <Select multiple id={id} value={value} onChange={handleChange}>
        {options.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
