import styled from "@emotion/styled";
import { FaChevronLeft } from "@react-icons/all-files/fa/FaChevronLeft";
import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight";
import { FaTimes } from "@react-icons/all-files/fa/FaTimes";
import React, { useCallback, useEffect, useState } from "react";
import { DoctorProfilePage } from "../../../../../pages";
import { MOBILE_MD } from "../../../../../utils/breakpoints";
import { default as colors } from "../../../../../utils/colors.json";
import { getSpecialties, getTitle } from "../../../../../utils/doctor";
import { modulo } from "../../../../../utils/number";
import { toCMSAssetUrl } from "../../../../../utils/utils";
import { Link } from "../../../../../components/Link/Link";
import { Quote } from "../../../Quote/Quote";
import { DoctorAndLocation } from "../common";
import { IconButton } from "../IconButton/IconButton";

const Container = styled.article`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  max-width: 350px;
  margin: 32px auto;
  padding: 32px;

  border-radius: 16px;
  background-color: white;
  overflow-y: auto;
  font-size: 14px;

  @media (min-width: ${MOBILE_MD}) {
    left: auto;
    width: 350px;
    margin: 32px;
    padding: 32px;
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const DoctorTitle = styled.h1`
  line-height: normal;
  font-size: 1.5em;
  margin-bottom: 16px;
`;

const PartialQuote = styled(Quote)`
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;

  mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
  margin-bottom: 8px;
`;

const Heading = styled.h2`
  font-size: 1em;
  margin-bottom: 0;

  & > span {
    font-weight: normal;
  }
`;

const Paragraph = styled.p`
  margin: 0;
`;

const ExternalLink = styled.a`
  word-break: break-all;
`;

const List = styled.ul`
  margin: 0;

  & li {
    font-size: 1em;
  }
`;

const Avatar = ({ id, fullName }: { id: string; fullName: string }) => {
  return (
    <div className="w-32 h-32 rounded-full overflow-hidden flex justify-center items-center">
      <img src={toCMSAssetUrl(id, 128)} alt={fullName} />
    </div>
  );
};

const DoctorInfo: React.FunctionComponent<{ doctor: DoctorAndLocation }> = ({
  doctor: { doctor, location },
}) => {
  const remoteConsultationsLabel = doctor.remoteConsultations ? "Yes" : "No";
  const hasContact =
    Boolean(location.phoneNumber) || Boolean(location.emailAddress) || Boolean(location.website);
  const isLocationVisible = Boolean(doctor.locationVisibility);

  return (
    <>
      {doctor.profilePhoto && <Avatar id={doctor.profilePhoto.id} fullName={doctor.fullName} />}
      <DoctorTitle>{getTitle(doctor)}</DoctorTitle>

      {doctor.testimonial && <PartialQuote>{doctor.testimonial}</PartialQuote>}
      <Link to={DoctorProfilePage} params={{ slug: doctor.slug }}>
        Go to clinician's personal page
      </Link>

      <Heading>Specialties</Heading>
      <List>
        {getSpecialties(doctor).map((specialty) => (
          <li key={specialty}>{specialty}</li>
        ))}
      </List>

      {hasContact && (
        <>
          <Heading>Contact</Heading>
          <Paragraph>
            <ExternalLink href={`tel:${location.phoneNumber}`}>{location.phoneNumber}</ExternalLink>
          </Paragraph>
          <Paragraph>
            <ExternalLink href={`mailto:${location.emailAddress}`}>{location.emailAddress}</ExternalLink>
          </Paragraph>
          <Paragraph>
            <ExternalLink href={location.website} target="_blank" rel="noopener noreferrer">
              {location.website}
            </ExternalLink>
          </Paragraph>
        </>
      )}

      {isLocationVisible && (
        <>
          <Heading>Location</Heading>
          <Paragraph>{location.name}</Paragraph>
          <Paragraph>{location.address}</Paragraph>
        </>
      )}

      <Heading>
        Remote consultations: <span>{remoteConsultationsLabel}</span>
      </Heading>
    </>
  );
};

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;

  font-weight: 700;
  font-size: 17px;
  line-height: 28px;
  color: ${colors.PINK};

  & > *:first-child {
    margin-right: 12px;
    padding-left: 6px;
    color: ${colors.GREY};
    &:hover {
      color: ${colors.GREEN};
    }
  }
  & > *:last-child {
    margin-left: 12px;
    padding-right: 6px;
    color: ${colors.GREY};
    &:hover {
      color: ${colors.GREEN};
    }
  }
`;

interface PaginationProps {
  index: number;
  maxIndex: number;
  onPreviousClick: () => void;
  onNextClick: () => void;
}

const Pagination: React.FunctionComponent<PaginationProps> = ({
  index,
  maxIndex,
  onPreviousClick,
  onNextClick,
}) => {
  return (
    <PaginationContainer>
      <IconButton onClick={onPreviousClick}>
        <FaChevronLeft />
      </IconButton>
      {index} of {maxIndex}
      <IconButton onClick={onNextClick}>
        <FaChevronRight />
      </IconButton>
    </PaginationContainer>
  );
};

interface DoctorPanelProps {
  doctors: DoctorAndLocation[];
  onCloseClick: () => void;
}

export const DoctorPanel: React.FunctionComponent<DoctorPanelProps> = ({ doctors, onCloseClick }) => {
  const doctorsCount = doctors.length;
  const showPagination = doctorsCount > 1;

  const [index, setIndex] = useState(0);
  const [doctor, setDoctor] = useState(doctors[0]);

  // Reset both index and doctor when a new group of doctors is selected.
  useEffect(() => {
    setIndex(0);
    setDoctor(doctors[0]);
  }, [doctors, setIndex, setDoctor]);

  useEffect(() => {
    setDoctor(doctors[index]);
  }, [doctors, index, setDoctor]);

  const handlePreviousClick = useCallback(() => {
    setIndex((index) => modulo(index - 1, doctorsCount));
  }, [setIndex, doctorsCount]);
  const handleNextClick = useCallback(() => {
    setIndex((index) => modulo(index + 1, doctorsCount));
  }, [setIndex, doctorsCount]);

  return (
    <Container>
      <CloseButton onClick={onCloseClick}>
        <FaTimes />
      </CloseButton>

      {showPagination && (
        <Pagination
          index={index + 1}
          maxIndex={doctorsCount}
          onPreviousClick={handlePreviousClick}
          onNextClick={handleNextClick}
        />
      )}

      <DoctorInfo doctor={doctor} />
    </Container>
  );
};
