import React, { useState, useContext, useCallback, useEffect, useMemo } from "react";
import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete, { AutocompleteRenderInputParams } from "@mui/material/Autocomplete";
import { MOBILE_LG } from "../../../../utils/breakpoints";
import LoadingBase from "../../../../components/Loading/Loading";
import { MultiSelect } from "../../../../components/MultiSelect/MultiSelect";
import { DoctorsMap as MapBase } from "./DoctorsMap/DoctorsMap";
import {
  useGoogleMapsScript,
  UseGoogleMapsOptions,
} from "../../../../hooks/useGoogleMapsScript/useGoogleMapsScript";
import { DoctorsContext } from "../../DoctorsProvider/DoctorsProvider";
import { DoctorPanel } from "./DoctorPanel/DoctorPanel";
import { DoctorGroup } from "./common";
import { usePlaces } from "../../../../graphql/usePlaces/usePlaces";
import { LatLngBounds } from "../../../../types/location/LatLngBounds";
import { logError } from "../../../../utils/logging";
import { PlaceSummary } from "../../../../types/location/Place";

const SearchControls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    width: 100%;
    margin-bottom: 16px;
  }

  @media (min-width: ${MOBILE_LG}) {
    flex-direction: row;
    margin-bottom: 32px;

    & > * {
      flex-grow: 1;
      margin-right: 32px;
      margin-bottom: 0;
    }
    & > *:last-child {
      margin-right: 0;
    }
  }
`;

const MAP_HEIGHT = "80vh";

const MapContainer = styled.div`
  position: absolute;
  left: 0;
  height: ${MAP_HEIGHT};
  width: 100vw;
`;

const Map = styled(MapBase)`
  width: 100%;
  height: 100%;
`;

const MapPadding = styled.div`
  height: ${MAP_HEIGHT};
`;

const Loading = styled(LoadingBase)`
  height: 200px;
`;

const googleMapsOptions: UseGoogleMapsOptions = {
  libraries: ["places"],
};

function getPlaceSummaryLabel(placeSummary: PlaceSummary): string {
  return placeSummary.name;
}

function comparePlaceSummary(placeSummary1: PlaceSummary, placeSummary2: PlaceSummary): boolean {
  return placeSummary1.id === placeSummary2.id;
}

export const FindDoctors: React.FunctionComponent = () => {
  const { loaded: isGoogleMapsScriptLoaded, error: googleMapsScriptLoadError } =
    useGoogleMapsScript(googleMapsOptions);
  const [map, setMap] = useState<google.maps.Map>();

  const { doctors, professionFilter, specialtyFilter, remoteConsultationsFilter } = useContext(DoctorsContext);
  const professionFilterOptions = useMemo(
    () => professionFilter.options.map(({ slug, name }) => ({ value: slug, label: name })),
    [professionFilter.options]
  );
  const specialtyFilterOptions = useMemo(
    () => specialtyFilter.options.map(({ slug, name }) => ({ value: slug, label: name })),
    [specialtyFilter.options]
  );
  const handleRemoteConsultationsSwitchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      remoteConsultationsFilter.setValue(event.target.checked);
    },
    [remoteConsultationsFilter.setValue]
  );

  const [selectedDoctorGroup, selectDoctorGroup] = useState<DoctorGroup>();
  const deselectDoctorGroup = useCallback(() => {
    selectDoctorGroup(undefined);
  }, [selectDoctorGroup]);

  const {
    placeInput,
    searchPlaces,
    placeOptions,
    selectPlace,
    place,
    loading: placeLoading,
  } = usePlaces({
    map,
    searchDebounceMillis: 200,
  });

  const handlePlaceInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      searchPlaces(event.target.value);
    },
    [searchPlaces]
  );
  const handlePlaceSelect = useCallback(
    (event: React.ChangeEvent<any>, placeSummary: PlaceSummary | null) => {
      if (placeSummary) {
        selectPlace(placeSummary);
      }
    },
    [selectPlace]
  );
  const renderPlaceAutocompleteInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        label="Location"
        placeholder="Address, city, region, country"
        variant="outlined"
        onChange={handlePlaceInputChange}
      />
    ),
    [handlePlaceInputChange]
  );

  const [viewport, setViewport] = useState<LatLngBounds>();

  const panToViewport = useCallback(() => {
    if (!map || !viewport) {
      return;
    }

    map.fitBounds(viewport);
  }, [map, viewport]);
  useEffect(panToViewport, [panToViewport]);

  useEffect(() => {
    if (place) {
      setViewport(place.bounds);
    }
  }, [place]);

  if (!isGoogleMapsScriptLoaded) {
    return <Loading />;
  } else if (googleMapsScriptLoadError) {
    logError("Failed to load Google Maps script", googleMapsScriptLoadError);
    return <p>Sorry, we can't display the Find a doctor map right now.</p>;
  }

  return (
    <>
      <SearchControls>
        <Autocomplete
          options={placeOptions}
          inputValue={placeInput}
          renderInput={renderPlaceAutocompleteInput}
          loading={placeLoading}
          getOptionLabel={getPlaceSummaryLabel}
          onChange={handlePlaceSelect}
          isOptionEqualToValue={comparePlaceSummary}
          autoComplete
          autoHighlight
          blurOnSelect
          disableClearable
          forcePopupIcon={false}
        />

        <MultiSelect
          label="Professions"
          value={professionFilter.values}
          onChange={professionFilter.setValues}
          options={professionFilterOptions}
        />

        <MultiSelect
          label="Specialties"
          value={specialtyFilter.values}
          onChange={specialtyFilter.setValues}
          options={specialtyFilterOptions}
        />

        <FormControlLabel
          control={
            <Switch
              checked={remoteConsultationsFilter.value}
              onChange={handleRemoteConsultationsSwitchChange}
            />
          }
          label="Remote consultations"
        />
      </SearchControls>

      <MapContainer>
        <Map
          doctors={doctors}
          selectedGroupLocation={selectedDoctorGroup?.geoLocation}
          onGroupSelect={selectDoctorGroup}
          onMapLoad={setMap}
        />
        {selectedDoctorGroup && (
          <DoctorPanel doctors={selectedDoctorGroup.doctors} onCloseClick={deselectDoctorGroup} />
        )}
      </MapContainer>
      <MapPadding />
    </>
  );
};
