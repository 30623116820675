export type GoogleMap = google.maps.Map;
export type AutocompleteService = google.maps.places.AutocompleteService;
export type PlacesService = google.maps.places.PlacesService;
export type SessionToken = google.maps.places.AutocompleteSessionToken;
export type AutocompletePrediction = google.maps.places.AutocompletePrediction;
export type PlaceResult = google.maps.places.PlaceResult;
export type GeocoderAddressComponent = google.maps.GeocoderAddressComponent;

export function createAutocompleteService(): AutocompleteService {
  return new google.maps.places.AutocompleteService();
}

export function createPlacesService(container: HTMLDivElement | google.maps.Map): PlacesService {
  return new google.maps.places.PlacesService(container);
}

export function createSessionToken(): SessionToken {
  return new google.maps.places.AutocompleteSessionToken();
}

export async function getPlacePredictions(
  service: AutocompleteService,
  input: string,
  sessionToken: SessionToken,
  options: Partial<google.maps.places.AutocompletionRequest> = {}
): Promise<AutocompletePrediction[] | null> {
  return new Promise((resolve, reject) => {
    const callback = (
      result: google.maps.places.AutocompletePrediction[] | null,
      status: google.maps.places.PlacesServiceStatus
    ) => {
      switch (status) {
        case google.maps.places.PlacesServiceStatus.OK:
          resolve(result);
          break;
        case google.maps.places.PlacesServiceStatus.ZERO_RESULTS:
          resolve([]);
          break;
        default:
          reject(new GoogleMapsApiError(status));
          break;
      }
    };

    service.getPlacePredictions(
      {
        input,
        sessionToken,
        ...options,
      },
      callback
    );
  });
}

export async function getPlaceDetails(
  service: PlacesService,
  id: string,
  sessionToken: SessionToken
): Promise<PlaceResult | null> {
  return new Promise((resolve, reject) => {
    const callback = (
      result: google.maps.places.PlaceResult | null,
      status: google.maps.places.PlacesServiceStatus
    ) => {
      switch (status) {
        case google.maps.places.PlacesServiceStatus.OK:
          resolve(result);
          break;
        default:
          reject(new GoogleMapsApiError(status));
          break;
      }
    };

    service.getDetails(
      {
        placeId: id,
        sessionToken,
      },
      callback
    );
  });
}

// Copy of `google.maps.places.PlacesServiceStatus`.
// We need this because `google` namespace isn't available during tests.
// This should be risk free because this enum is very unlikely to change.
export enum PlacesServiceStatus {
  INVALID_REQUEST = "INVALID_REQUEST",
  NOT_FOUND = "NOT_FOUND",
  OK = "OK",
  OVER_QUERY_LIMIT = "OVER_QUERY_LIMIT",
  REQUEST_DENIED = "REQUEST_DENIED",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  ZERO_RESULTS = "ZERO_RESULTS",
}

export class GoogleMapsApiError extends Error {
  public readonly status: PlacesServiceStatus;

  constructor(status: PlacesServiceStatus) {
    super();
    this.status = status;
  }
}
