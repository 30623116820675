import { RangeType } from "../constants/types";
import { sendEvent } from "./gtm";
import { isDevelopment } from "./isDevelopment";

export const tryOrUndefined = <T>(fn: Function): T | undefined => {
  try {
    return fn();
  } catch (e) {
    return undefined;
  }
};

export const encodeUserIdForFirestore = (str: string) => str.replace(/^users\//, "");

export const getMinMaxValue = (rangeValues: RangeType[]) => {
  if (rangeValues.length > 0) {
    const maxValue = rangeValues.some((item) => item.max === null)
      ? null
      : Math.max(...rangeValues.map((item) => item.max as number));

    const minValue = rangeValues.some((item) => item.min === null)
      ? null
      : Math.min(...rangeValues.map((item) => item.min as number));
    return {
      min: minValue,
      max: maxValue,
    };
  }
  return {
    min: null,
    max: null,
  };
};

export const getSelectedCookingTimeRange = (selectedRange: RangeType[], currentCookTimeLimit: RangeType) => {
  const isRangeSelected = selectedRange.some((selected) => selected.id === currentCookTimeLimit.id);

  const maxCond = selectedRange.some((item) => item.max === null) && currentCookTimeLimit.id === "0-14";
  const minCond = selectedRange.some((item) => item.min === null) && currentCookTimeLimit.id === "45-P";

  if (maxCond) {
    const cookTimeValues = selectedRange.filter((item) => item.max !== null);
    return [...cookTimeValues, currentCookTimeLimit];
  } else if (minCond) {
    const cookTimeValues = selectedRange.filter((item) => item.min !== null);
    return [...cookTimeValues, currentCookTimeLimit];
  } else {
    return isRangeSelected
      ? selectedRange.filter((range) => range.id !== currentCookTimeLimit.id)
      : [...selectedRange, currentCookTimeLimit];
  }
};

export const getSelectedSatietyScoreRange = (
  selectedRange: RangeType[],
  currentSatietyScoreRange: RangeType
) => {
  const isRangeSelected = selectedRange.some((selected) => selected.id === currentSatietyScoreRange.id);

  const maxCond = selectedRange.some((item) => item.max === 100) && currentSatietyScoreRange.id === "0-39";
  const minCond = selectedRange.some((item) => item.min === 0) && currentSatietyScoreRange.id === "60-P";

  const rangeId = currentSatietyScoreRange.id;
  const level = currentSatietyScoreRange.payload;

  if (!isRangeSelected) {
    const isModerateSelected = selectedRange.some((item) => item.id === "40-59");
    const isHighSelected = selectedRange.some((item) => item.id === "60-P");
    const isLowSelected = selectedRange.some((item) => item.id === "0-39");

    if ((isModerateSelected && rangeId === "0-39") || (isLowSelected && rangeId === "40-59")) {
      sendEvent({
        eventName: "filter_satiety_tag",
        action: "click",
        payload: { level: "low-moderate" },
      });
    } else if ((isModerateSelected && rangeId === "60-P") || (isHighSelected && rangeId === "40-59")) {
      sendEvent({
        eventName: "filter_satiety_tag",
        action: "click",
        payload: { level: "high-moderate" },
      });
    } else {
      sendEvent({
        eventName: "filter_satiety_tag",
        action: "click",
        payload: { level },
      });
    }
  }

  if (maxCond) {
    const satietyScoreValues = selectedRange.filter((item) => item.max !== 100);
    return [...satietyScoreValues, currentSatietyScoreRange];
  } else if (minCond) {
    const satietyScoreValues = selectedRange.filter((item) => item.min !== 0);
    return [...satietyScoreValues, currentSatietyScoreRange];
  } else {
    return isRangeSelected
      ? selectedRange.filter((range) => range.id !== currentSatietyScoreRange.id)
      : [...selectedRange, currentSatietyScoreRange];
  }
};

const urlWithQuality =
  (quality = 90) =>
  (url: string) =>
    `${url}?quality=${quality}`;

const urlWithWidth = (width: number) => (url: string) => `${url}&width=${width}`;
const urlWithHeight = (height: number) => (url: string) => `${url}&height=${height}`;
const urlWithFit =
  (fit: "cover" | "contain" | "inside" | "outside" = "outside") =>
  (url: string) =>
    `${url}&fit=${fit}`;

const cmsUrlWithEnv = (isDev: boolean) => (id: string) =>
  isDev ? `https://cms.dev.dietdoctor.com/assets/${id}` : `https://cms.prod.dietdoctor.com/assets/${id}`;

export const toCMSAssetUrl = (
  id: string,
  width: number,
  height?: number,
  fit?: "cover" | "contain" | "inside" | "outside",
  quality?: number
): string =>
  [id]
    .map(cmsUrlWithEnv(isDevelopment()))
    .map(urlWithQuality(quality))
    .map(urlWithWidth(width))
    .map(urlWithHeight(height ?? width))
    .map(urlWithFit(fit))[0];
