import { Doctor, Location, PartialDoctor } from "../types/directus/Doctor";

export function getTitle(doctor: Doctor | PartialDoctor): string {
  if (doctor?.preferredName?.length > 0) {
    return doctor.preferredName;
  }
  const degrees = doctor.degrees.map((item) => item.degree.abbreviation);

  return [doctor.fullName, ...degrees].join(", ");
}

export function getDegrees(doctor: Doctor): string[] {
  if (doctor.additionalDegrees) {
    return [...doctor.degrees.map((item) => item.degree.name), ...doctor.additionalDegrees.split(", ")].filter(
      (entry) => entry.length > 0
    );
  }
  return [...doctor.degrees.map((item) => item.degree.name)].filter((entry) => entry.length > 0);
}

export function getSpecialties(doctor: Doctor | PartialDoctor): string[] {
  if (doctor.additionalSpecialties) {
    return [
      ...doctor.specialties.map((item) => item.speciality.name),
      ...doctor.additionalSpecialties.split(", "),
    ].filter((entry) => entry.length > 0);
  }
  return [...doctor.specialties.map((item) => item.speciality.name)].filter((entry) => entry.length > 0);
}

export function getLchfTrainingPrograms(doctor: Doctor): string[] {
  return doctor.trainingPrograms.map((item) => item.trainingProgram.name);
}

export function getPrimaryLocation(doctor: Doctor | PartialDoctor): Location | undefined {
  return doctor.locations.length > 0 ? doctor.locations[0] : undefined;
}
