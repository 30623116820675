export function getRandomInteger(max: number): number {
  return Math.floor(Math.random() * Math.floor(max));
}

export function modulo(dividend: number, divisor: number): number {
  return ((dividend % divisor) + divisor) % divisor;
}

const hasIntlNumberFormat = () => typeof Intl !== "undefined" && typeof Intl.NumberFormat !== "undefined";

export function formatNumber(value: number): string {
  if (hasIntlNumberFormat()) {
    return new Intl.NumberFormat().format(value);
  }
  return `${value}`;
}
