import styled from "@emotion/styled";
import { FaQuoteLeft } from "@react-icons/all-files/fa/FaQuoteLeft";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { DoctorProfilePage } from "../../../../pages";
import { PartialDoctor } from "../../../../types/directus/Doctor";
import { MOBILE_MD } from "../../../../utils/breakpoints";
import { default as colors } from "../../../../utils/colors.json";
import { getPrimaryLocation, getTitle } from "../../../../utils/doctor";
import { getRandomInteger } from "../../../../utils/number";
import { toCMSAssetUrl } from "../../../../utils/utils";
import { DoctorsContext } from "../../DoctorsProvider/DoctorsProvider";
import { Link } from "../../../../components/Link/Link";
import { Quote } from "../../Quote/Quote";

const Avatar = ({ id, fullName }: { id: string; fullName: string }) => {
  return (
    <div className="w-16 h-16 mr-4 rounded-full overflow-hidden flex justify-center items-center">
      <img src={toCMSAssetUrl(id, 64)} alt={fullName} />
    </div>
  );
};

const DoctorTitle = styled.h3`
  font-size: 1.1em;
  margin: 0;
`;

const DoctorLink = styled.span`
  & > a {
    color: ${colors.BLUE};
  }
`;

const DoctorLocation = styled.p`
  font-size: 0.8em;
  margin: 0;
`;

const DoctorInfoContainer = styled.div`
  position: relative;
  left: -32px;

  display: flex;
  align-items: center;

  overflow-wrap: anywhere;

  @media (min-width: ${MOBILE_MD}) {
    left: 0;
  }
`;

const DoctorInfo: React.FunctionComponent<{ doctor: PartialDoctor }> = ({ doctor }) => {
  const primaryLocation = getPrimaryLocation(doctor);

  return (
    <DoctorInfoContainer>
      {doctor.profilePhoto && <Avatar id={doctor.profilePhoto.id} fullName={doctor.fullName} />}
      <div>
        <DoctorTitle>
          <DoctorLink>
            <Link to={DoctorProfilePage} params={{ slug: doctor.slug }}>
              {getTitle(doctor)}
            </Link>
          </DoctorLink>
        </DoctorTitle>
        {primaryLocation?.name && <DoctorLocation>{primaryLocation.name}</DoctorLocation>}
      </div>
    </DoctorInfoContainer>
  );
};

const TestimonialContainer = styled.article`
  display: flex;
  flex-direction: row;

  margin-top: 16px;

  & > *:first-child {
    margin-right: 16px;
  }
`;

const QuoteIcon = styled(FaQuoteLeft)`
  position: relative;
  top: -16px;

  flex-shrink: 0;
  width: 48px;
  height: 48px;

  color: ${colors.BLUE_LIGHTER};

  @media (min-width: ${MOBILE_MD}) {
    width: 96px;
    height: 96px;
  }
`;

const QuoteContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > *:first-child {
    margin-bottom: 16px;
  }
`;

const Testimonial: React.FunctionComponent<{ doctor: PartialDoctor }> = ({ doctor }) => (
  <TestimonialContainer>
    <QuoteIcon />
    <QuoteContainer>
      {doctor?.testimonial && <Quote>{doctor.testimonial}</Quote>}
      <DoctorInfo doctor={doctor} />
    </QuoteContainer>
  </TestimonialContainer>
);

const CAROUSEL_INTERVAL_MS = 15_000;

function isDoctorShowcased(doctor: PartialDoctor): boolean {
  return doctor?.testimonial?.length > 0 && Boolean(doctor.profilePhoto);
}

export const Testimonials: React.FunctionComponent = () => {
  const { allDoctors } = useContext(DoctorsContext);
  const showcasedDoctors = useMemo(() => allDoctors.filter(isDoctorShowcased), [allDoctors]);
  const [currentIdx, setCurrentIdx] = useState(getRandomInteger(showcasedDoctors.length));

  useEffect(() => {
    const handle = setInterval(() => {
      setCurrentIdx((currentIdx + 1) % showcasedDoctors.length);
    }, CAROUSEL_INTERVAL_MS);

    return () => {
      clearInterval(handle);
    };
  }, [showcasedDoctors, currentIdx, setCurrentIdx]);

  return <Testimonial doctor={showcasedDoctors[currentIdx]} />;
};
