import { useEffect, useMemo, useState } from "react";
import { PartialDoctor } from "../../types/directus/Doctor";
import { MedicalProfession } from "../../types/directus/MedicalProfession";
import { MedicalSpecialty } from "../../types/directus/MedicalSpecialty";

interface MedicalProfessionFilter {
  options: MedicalProfession[];
  values: string[];
  setValues: (values: string[]) => void;
}

interface MedicalSpecialtyFilter {
  options: MedicalSpecialty[];
  values: string[];
  setValues: (values: string[]) => void;
}

interface RemoteConsultationsFilter {
  value: boolean;
  setValue: (value: boolean) => void;
}

interface HasSlug {
  slug: string;
}

function createMapOfSelectedEntries(entries: string[]): Record<string, boolean> {
  return entries.reduce((map, slug) => ({ ...map, [slug]: true }), {});
}

function doctorHasAtLeastOneSelectedEntry(
  selectedEntriesMap: Record<string, boolean>,
  doctorEntries: HasSlug[]
): boolean {
  if (doctorEntries?.length && doctorEntries[0]?.slug) {
    return doctorEntries.some(({ slug }) => Boolean(selectedEntriesMap[slug]));
  }
  return false;
}

function pureSpecialties(specialties: [Record<"speciality", MedicalSpecialty>]) {
  return specialties.map((item) => item.speciality);
}

function filterDoctors(
  doctors: PartialDoctor[],
  professions: string[],
  specialties: string[],
  remoteConsultations: boolean
): PartialDoctor[] {
  const noSelectedProfessions = professions.length === 0;
  const noSelectedSpecialties = specialties.length === 0;

  const selectedProfessionMap = createMapOfSelectedEntries(professions);
  const selectedSpecialtyMap = createMapOfSelectedEntries(specialties);

  return doctors.filter(
    (doctor) =>
      (noSelectedProfessions || doctorHasAtLeastOneSelectedEntry(selectedProfessionMap, [doctor.profession])) &&
      (noSelectedSpecialties ||
        doctorHasAtLeastOneSelectedEntry(selectedSpecialtyMap, pureSpecialties(doctor.specialties))) &&
      (!remoteConsultations || doctor.remoteConsultations)
  );
}

export interface DoctorFilterOptions {
  professions: MedicalProfession[];
  specialties: MedicalSpecialty[];
}

export interface DoctorsResult {
  allDoctors: PartialDoctor[];
  doctors: PartialDoctor[];
  professionFilter: MedicalProfessionFilter;
  specialtyFilter: MedicalSpecialtyFilter;
  remoteConsultationsFilter: RemoteConsultationsFilter;
}

export function useDoctors(allDoctors: PartialDoctor[], filterOptions: DoctorFilterOptions): DoctorsResult {
  const [doctors, setDoctors] = useState(allDoctors);

  const [professions, setProfessions] = useState<string[]>([]);
  const professionFilter = useMemo(
    () => ({
      options: filterOptions.professions,
      values: professions,
      setValues: setProfessions,
    }),
    [filterOptions.professions, professions, setProfessions]
  );

  const [specialties, setSpecialties] = useState<string[]>([]);
  const specialtyFilter = useMemo(
    () => ({
      options: filterOptions.specialties,
      values: specialties,
      setValues: setSpecialties,
    }),
    [filterOptions.specialties, specialties, setSpecialties]
  );

  const [remoteConsultations, setRemoteConsultations] = useState(false);
  const remoteConsultationsFilter = useMemo(
    () => ({
      value: remoteConsultations,
      setValue: setRemoteConsultations,
    }),
    [remoteConsultations, setRemoteConsultations]
  );

  useEffect(() => {
    const filteredDoctors = filterDoctors(allDoctors, professions, specialties, remoteConsultations);
    setDoctors(filteredDoctors);
  }, [allDoctors, professions, specialties, remoteConsultations, setDoctors]);

  return {
    allDoctors,
    doctors,
    professionFilter,
    specialtyFilter,
    remoteConsultationsFilter,
  };
}
