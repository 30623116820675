import { useRef, useEffect, useCallback } from "react";
import { SessionToken, createSessionToken, GoogleMap } from "./google-maps";

export function useSessionToken(map?: GoogleMap) {
  const sessionToken = useRef<SessionToken>();

  useEffect(() => {
    if (map) {
      refreshSessionToken();
    }
  }, [map]);

  const refreshSessionToken = useCallback(() => {
    sessionToken.current = createSessionToken();
  }, []);

  return {
    sessionToken: sessionToken,
    refreshSessionToken,
  };
}
