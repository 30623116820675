import { useLoadScript } from "@react-google-maps/api";

declare type Libraries = ("drawing" | "geometry" | "localContext" | "places" | "visualization")[];
export interface UseGoogleMapsOptions {
  libraries?: Libraries;
}

export function useGoogleMapsScript({ libraries }: UseGoogleMapsOptions = {}) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY as string,
    libraries,
  });

  return {
    loaded: isLoaded,
    error: loadError,
  };
}
