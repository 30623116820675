import React from "react";
import styled from "@emotion/styled";

const Button = styled.button`
  width: 2em;
  padding: 0;
  border-width: 0;
  background-color: transparent;

  cursor: pointer;

  & > svg {
    width: 100%;
    height: 1.5em;
  }
`;

interface IconButtonProps {
  className?: string;
  onClick?: () => void;
  children?: React.ReactNode;
}

export const IconButton: React.FunctionComponent<IconButtonProps> = ({ className, onClick, children }) => (
  <Button className={className} onClick={onClick}>
    {children}
  </Button>
);
