import React from "react";
import { DoctorFilterOptions, DoctorsResult, useDoctors } from "../../../graphql/useDoctors/useDoctors";
import { PartialDoctor } from "../../../types/directus/Doctor";

export const DoctorsContext = React.createContext<DoctorsResult>({
  allDoctors: [],
  doctors: [],
  professionFilter: {
    options: [],
    values: [],
    setValues: () => {},
  },
  specialtyFilter: {
    options: [],
    values: [],
    setValues: () => {},
  },
  remoteConsultationsFilter: {
    value: false,
    setValue: () => {},
  },
});

interface DoctorsProviderProps {
  doctors: PartialDoctor[];
  filterOptions: DoctorFilterOptions;
  children?: React.ReactNode;
}

export const DoctorsProvider: React.FunctionComponent<DoctorsProviderProps> = ({
  doctors,
  filterOptions,
  children,
}) => {
  const doctorsResult = useDoctors(doctors, filterOptions);

  return <DoctorsContext.Provider value={doctorsResult}>{children}</DoctorsContext.Provider>;
};
