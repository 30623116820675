import styled from "@emotion/styled";
import { MOBILE_MD, MOBILE_LG, LAPTOP_SM, LAPTOP } from "../../../utils/breakpoints";

export const PageContent = styled.div`
  max-width: 1200px;
  padding: 1.667rem 20px 20px;
  margin: 0 auto;

  @media (min-width: ${MOBILE_MD}) {
    padding: 70px 50px 50px;
  }
  @media (min-width: ${MOBILE_LG}) {
    padding: 70px 50px 50px;
  }
  @media (min-width: ${LAPTOP_SM}) {
    margin: 0 auto;
  }
  @media (min-width: ${LAPTOP}) {
    padding: 70px 0 50px 0;
    margin: 0 auto;
  }
`;

export const PageSection = styled.div`
  max-width: 800px;

  @media (min-width: ${MOBILE_LG}) {
    max-width: 66%;
  }
`;

export const Heading1 = styled.h1`
  font-size: 2.11rem;
  font-weight: 700;
  margin: 0 0 0.8rem;
  line-height: 1.184;
  letter-spacing: -0.15px;

  @media (min-width: ${MOBILE_LG}) {
    font-size: 3.44rem;
    margin: 0 0 0.8rem;
    line-height: 1.048;
    letter-spacing: -0.34px;
  }

  &:first-child {
    margin-top: 30px;
  }
`;

export const Heading2 = styled.h2`
  font-size: 1.555rem;
  font-weight: 700;
  margin: 3rem 0 10px;
  line-height: 1.142;
  letter-spacing: 0.1px;

  @media (min-width: ${MOBILE_LG}) {
    font-size: 2.666rem;
    margin: 2.5rem 0 2.5rem;
    line-height: 1.083;
    letter-spacing: 0.1px;
  }
`;
