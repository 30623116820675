import { GoogleMap, GoogleMapProps, Marker } from "@react-google-maps/api";
import React, { useCallback, useMemo } from "react";
import { PartialDoctor } from "../../../../../types/directus/Doctor";
import { getTitle } from "../../../../../utils/doctor";
import { DoctorGroup } from "../common";
import { getMapType } from "./map-type";
import MarkerIcon from "./marker.svg";
import SelectedMarkerLargeIcon from "./selected-marker-large.svg";
import SelectedMarkerIcon from "./selected-marker.svg";

function createLocationKey(location: [number, number]): string {
  return `${location[0]},${location[1]}`;
}

const constMapProps: Partial<GoogleMapProps> = {
  id: "find-doctors-map",
  zoom: 2,
  center: {
    lat: 0,
    lng: 0,
  },
  options: {
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    zoomControl: false,
    // This is a nice to have so users won't be able to zoom out or pan away from the map,
    // but then fitBounds() method doesn't work property:
    // https://issuetracker.google.com/issues/124546317
    // restriction: {
    //   latLngBounds: {
    //     north: 85,
    //     south: -85,
    //     west: -180,
    //     east: 180,
    //   },
    //   strictBounds: true,
    // },
  },
};

interface DoctorsMapProps {
  doctors: PartialDoctor[];
  selectedGroupLocation?: [number, number];
  onGroupSelect: (group: DoctorGroup) => void;
  onMapLoad: (map: google.maps.Map) => void;
  className?: string;
}

export const DoctorsMap: React.FunctionComponent<DoctorsMapProps> = ({
  doctors,
  selectedGroupLocation,
  onGroupSelect,
  onMapLoad,
  className,
}) => {
  const markerIcon = useMemo(
    () => ({
      url: MarkerIcon,
      anchor: new google.maps.Point(8, 8),
    }),
    []
  );
  const selectedMarkerIcon = useMemo(
    () => ({
      url: SelectedMarkerIcon,
      anchor: new google.maps.Point(8, 8),
    }),
    []
  );
  const selectedMarkerLargeIcon = useMemo(
    () => ({
      url: SelectedMarkerLargeIcon,
      anchor: new google.maps.Point(16, 16),
    }),
    []
  );
  const [mapTypeName, mapType] = useMemo(getMapType, []);

  const handleLoad = useCallback(
    (map: google.maps.Map) => {
      map.mapTypes.set(mapTypeName, mapType);
      map.setMapTypeId(mapTypeName);
      onMapLoad(map);
    },
    [mapType, mapTypeName, onMapLoad]
  );

  const handleMarkerClick = useCallback((group: DoctorGroup) => () => onGroupSelect(group), [onGroupSelect]);

  const markers = useMemo(() => {
    const doctorsPerLocation: Record<string, DoctorGroup> = {};
    let doctorGroupCount = 0;

    doctors.forEach((doctor) => {
      doctor.locations.forEach((location) => {
        if (location?.location?.coordinates) {
          const locationKey = createLocationKey(location?.location?.coordinates);

          if (doctorsPerLocation[locationKey]) {
            doctorsPerLocation[locationKey].doctors.push({ doctor, location });
          } else {
            doctorGroupCount++;
            doctorsPerLocation[locationKey] = {
              geoLocation: location.location.coordinates,
              doctors: [{ doctor, location }],
            };
          }
        }
      });
    });

    const selectedGroupLocationKey = selectedGroupLocation && createLocationKey(selectedGroupLocation);

    return Object.entries(doctorsPerLocation).map(([locationKey, group], index) => {
      const { geoLocation, doctors } = group;
      const key = `${locationKey}-${doctors.map(({ doctor }) => doctor.slug).join(",")}`;

      const title = doctors.length === 1 ? getTitle(doctors[0].doctor) : `${doctors.length} doctors`;
      const position = { lng: geoLocation[0], lat: geoLocation[1] };
      const onClick = handleMarkerClick(group);

      const isSelected = selectedGroupLocationKey && selectedGroupLocationKey === locationKey;
      const zIndex = isSelected ? doctorGroupCount : index;
      const icon = isSelected
        ? doctors.length > 1
          ? selectedMarkerLargeIcon
          : selectedMarkerIcon
        : markerIcon;
      const label =
        isSelected && doctors.length > 1
          ? {
              text: `${doctors.length}`,
              color: "white",
              fontWeight: "700",
            }
          : undefined;

      return (
        <Marker
          key={key}
          title={title}
          position={position}
          zIndex={zIndex}
          icon={icon}
          label={label}
          onClick={onClick}
        />
      );
    });
  }, [
    doctors,
    selectedGroupLocation,
    handleMarkerClick,
    selectedMarkerLargeIcon,
    selectedMarkerIcon,
    markerIcon,
  ]);

  return (
    <GoogleMap mapContainerClassName={className} onLoad={handleLoad} {...constMapProps}>
      {markers}
    </GoogleMap>
  );
};
