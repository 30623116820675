import { Link } from "../../../components/Link/Link";
import React, { useContext } from "react";
import styled from "@emotion/styled";
import { Testimonials } from "./Testimonials/Testimonials";
import { PageContent, PageSection } from "../Wordpress/Wordpress";
import { AllDoctorsPage, DoctorsPage as PageDefinition, GetPageContext } from "../../../pages";
import { FindDoctors } from "./FindDoctors/FindDoctors";
import { DoctorsProvider, DoctorsContext } from "../DoctorsProvider/DoctorsProvider";
import { SEOReactHelmet } from "../../../components/SEO/SEOReactHelmet";
import { pageContext } from "../../../components/PageProvider/PageProvider";

const PageMain = styled.main`
  position: relative;

  // The map component spans over 100vw, which doesn't take into account the
  // scrollbar. Because of that it creates a tiny, but very annoying,
  // horizontal scroll. Setting "overflow-x" to "hidden" will hide the extra
  // part. Not an amazing solution as were hiding a piece of the map, but it
  // works.
  overflow-x: hidden;
`;

const DoctorsPageImpl: React.FunctionComponent = () => {
  const { allDoctors } = useContext(DoctorsContext);

  return (
    <PageMain>
      <PageContent>
        <h1>Global map of low-carb clinicians</h1>
        <PageSection>
          <p className="mt-3 text-lg">
            Are you looking for a doctor, nurse practitioner, dietitian, or other health professional to help
            you with your low-carb or keto lifestyle?
          </p>
          <p className="mt-3 text-lg">
            Do you need to have your medications managed as you cut carbohydrates? Or do you want to work with a
            knowledgeable expert to help you keep on track as you embrace low-carb or keto eating?
          </p>
          <p className="mt-3 text-lg">
            Use our searchable map below to find a physician, physician assistant, nurse or nurse practitioner,
            dietitian, health coach, or trained health professional near you. Many of them also do remote
            consultations over a video link or phone.
          </p>
          <p className="mt-3 text-lg">
            We now have {allDoctors.length} doctors and other healthcare professionals listed, and we’re
            constantly adding more. You can read their impressive testimonials.
          </p>
          <p className="mt-3 text-lg">
            Some can manage medications and others cannot. Non-prescribing providers have it noted on their
            profile.
          </p>
          <Link className="inline-block mt-3 hover:underline" to={AllDoctorsPage}>
            Directory of all health providers
          </Link>
        </PageSection>

        <h2 className="mt-8 mb-3">Find a health provider</h2>
        <FindDoctors />

        <h2 className="mt-8 mb-3">Doctors' low-carb testimonials</h2>
        <PageSection>
          <Testimonials />
        </PageSection>
      </PageContent>
    </PageMain>
  );
};

type DoctorsPageContext = GetPageContext<typeof PageDefinition>;

const DoctorsPageDefinition: React.FunctionComponent = () => {
  const {
    extraContext: { doctors, filterOptions },
  } = useContext<DoctorsPageContext>(pageContext);

  return (
    <>
      <SEOReactHelmet
        title="Find your doctor — Diet Doctor"
        thumbnail="images/thumbnails/dd-find-a-doctor.jpg"
      />
      <DoctorsProvider doctors={doctors} filterOptions={filterOptions}>
        <DoctorsPageImpl />
      </DoctorsProvider>
    </>
  );
};

// ts-prune-ignore-next
export default DoctorsPageDefinition;
