import { useState, useRef, useEffect } from "react";
import { PlaceSummary, Place } from "../../types/location/Place";
import { useDebounce } from "../../hooks/useDebounce/useDebounce";
import {
  AutocompleteService,
  PlacesService,
  createAutocompleteService,
  createPlacesService,
  getPlacePredictions,
  SessionToken,
  GoogleMapsApiError,
  getPlaceDetails,
  PlaceResult,
  AutocompletePrediction,
  GeocoderAddressComponent,
} from "./google-maps";
import { useSessionToken } from "./useSessionToken";
import { AddressComponent } from "../../types/location/AddressComponent";

const EMPTY_LIST: PlaceSummary[] = [];

interface UsePlacesOptions {
  map?: google.maps.Map;
  searchDebounceMillis?: number;
}

export function usePlaces({ map, searchDebounceMillis }: UsePlacesOptions = {}) {
  const autocompleteService = useRef<AutocompleteService>();
  const placesService = useRef<PlacesService>();

  // Create underlying Google services as soon as the map is loaded.
  // Do this only once.
  useEffect(() => {
    if (map && (!autocompleteService.current || !placesService.current)) {
      autocompleteService.current = createAutocompleteService();
      placesService.current = createPlacesService(map);
    }
  }, [map]);

  const { sessionToken, refreshSessionToken } = useSessionToken(map);
  const [placeInput, setPlaceInput] = useState("");
  const [placeOptions, setPlaceOptions] = useState<PlaceSummary[]>(EMPTY_LIST);
  const [place, setPlace] = useState<Place>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<PlaceError>();

  const searchPlaces = (placeQuery: string) => {
    setPlaceInput(placeQuery);
    setError(undefined);

    if (!placeQuery) {
      setPlaceOptions([]);
      return;
    }

    setLoading(true);
    debouncedSearchPlaces(placeQuery);
  };

  const debouncedSearchPlaces = useDebounce(async (placeQuery: string) => {
    if (!sessionToken.current || !autocompleteService.current) {
      setLoading(false);
      setError(new PlaceError("Not initialized"));
      setPlaceOptions(EMPTY_LIST);
      return;
    }

    try {
      const options = await searchPlacesImpl(autocompleteService.current, placeQuery, sessionToken.current);
      setPlaceOptions(options);
    } catch (error) {
      setPlaceOptions(EMPTY_LIST);
      setError(error as PlaceError);
    }

    setLoading(false);
  }, searchDebounceMillis);

  const selectPlace = async (placeOption: PlaceSummary) => {
    if (!sessionToken.current || !placesService.current) {
      setLoading(false);
      setError(new PlaceError("Not initialized"));
      setPlace(undefined);
      return;
    }

    setLoading(true);

    try {
      const place = await getPlaceDetailsImpl(placesService.current, placeOption.id, sessionToken.current);
      setPlace(place);
      setPlaceInput(place.address);
    } catch (error) {
      setPlace(undefined);
      setError(error as PlaceError);
    }

    setLoading(false);
    refreshSessionToken();
  };

  return { placeInput, place, placeOptions, searchPlaces, selectPlace, loading, error };
}

class PlaceError extends Error {
  constructor(message: string) {
    super(message);
  }
}

async function searchPlacesImpl(
  service: AutocompleteService,
  input: string,
  sessionToken: SessionToken
): Promise<PlaceSummary[]> {
  try {
    const predictions = await getPlacePredictions(service, input, sessionToken, {
      types: AUTOCOMPLETE_TYPES,
    });
    return (predictions as google.maps.places.AutocompletePrediction[]).map(toDietDoctorPlaceSummary);
  } catch (error) {
    if (error instanceof GoogleMapsApiError) {
      throw new PlaceError(error.status);
    } else {
      throw new PlaceError("Failed to search for places");
    }
  }
}

async function getPlaceDetailsImpl(
  service: PlacesService,
  id: string,
  sessionToken: SessionToken
): Promise<Place> {
  try {
    const result = await getPlaceDetails(service, id, sessionToken);
    const place = toDietDoctorPlace(result as google.maps.places.PlaceResult);
    if (place) {
      return place;
    } else {
      throw new PlaceError("Invalid place");
    }
  } catch (error) {
    if (error instanceof GoogleMapsApiError) {
      throw new PlaceError(error.status);
    } else if (error instanceof PlaceError) {
      throw error;
    } else {
      throw new PlaceError("Failed to select place");
    }
  }
}

const AUTOCOMPLETE_TYPES = ["geocode"];

function toDietDoctorPlaceSummary(prediction: AutocompletePrediction): PlaceSummary {
  return {
    id: prediction.place_id,
    name: prediction.description,
    types: prediction.types,
  };
}

function toDietDoctorPlace(placeResult: PlaceResult): Place | undefined {
  if (!placeResult.place_id) {
    return;
  }
  if (!placeResult.formatted_address) {
    return;
  }
  if (!placeResult.address_components) {
    return;
  }
  if (!placeResult.geometry) {
    return;
  }

  const addressComponents = placeResult.address_components
    .map(toDietDoctorAddressComponent)
    .filter(filterInvalidComponents);

  if (addressComponents.length === 0) {
    return;
  }

  return {
    id: placeResult.place_id,
    name: placeResult?.name,
    address: placeResult.formatted_address,
    addressComponents,
    coordinates: placeResult?.geometry.location?.toJSON(),
    bounds: placeResult?.geometry.viewport?.toJSON(),
  };
}

function toDietDoctorAddressComponent(component: GeocoderAddressComponent): AddressComponent | undefined {
  if (component.types.length === 0) {
    return;
  }

  return {
    longName: component.long_name,
    shortName: component.short_name,
    type: component.types[0],
  };
}

function filterInvalidComponents(component: AddressComponent | undefined): component is AddressComponent {
  return component !== undefined;
}
