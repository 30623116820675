import { useRef, useCallback } from "react";

export function useDebounce<F extends (...args: any[]) => any>(fn: F, delay?: number) {
  const handle = useRef<number>();

  const runFn = useCallback(
    (...args: any[]) => {
      handle.current = undefined;
      fn(...args);
    },
    [fn]
  );

  const debouncedFn = useCallback(
    (...args: any[]) => {
      if (handle.current) {
        clearTimeout(handle.current);
      }

      handle.current = window.setTimeout(runFn, delay || 0, ...args);
    },
    [handle, runFn, delay]
  );

  return debouncedFn;
}
